.video-page-container {
    position: relative;
    top: 3rem;
    padding: 0.8rem;
    min-height: 78vh;
    color: white;
}

@media (min-width: 60rem) {
    .video-page-container {
        padding: 2rem;
    }
}
