.footer-container {
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 0.3rem;
    padding: 0 0.8rem;
}

.footer-content {
    background-color: #201e1e;
    border-radius: 1rem;
    padding: 0.5rem 0;
}

.foooter-content__links {
    padding-inline-start: 0;
}

.foooter-content__link {
    margin: 1% 4%;
    display: block;
    text-align: center;
}

.foooter-content__link a {
    text-decoration: none;
    color: #ffffff;
}

.foooter-content__link a:hover,
.foooter-content__link a.active {
    color: red;
}

.copyright {
    color: #ffffff;
    text-align: center;
    padding: 1rem;
    padding-top: 0;
}

.copyright a {
    color: red;
    padding-right: 0.5rem;
}

@media (min-width: 40rem) {
    .footer-content {
        padding: 0;
    }

    .foooter-content__links {
        padding-inline-start: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 60rem) {
    .footer-container {
        padding: 0 2rem;
    }
}
