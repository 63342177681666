.page-not-found {
    color: #b11010;
    text-align: center;
    position: relative;
    top: 3rem;
    padding: 0.8rem;
    min-height: 78vh;
}

@media (min-width: 60rem) {
    .page-not-foundr {
        padding: 2rem;
    }
}
