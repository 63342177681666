.olimpo-hosting-brand-mobile img {
    display: block;
    width: 100%;
    border-radius: 1rem;
}

@media (min-width: 80rem) {
    .olimpo-hosting-brand-mobile {
        display: none;
    }
}
