.olimpo-hosting-brand img {
    display: none;
    width: 100%;
    border-radius: 1rem;
}

@media (min-width: 80rem) {
    .olimpo-hosting-brand img {
        display: block;
    }
}
