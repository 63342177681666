.main-container {
    position: relative;
    top: 3rem;
    padding: 0.8rem;
    min-height: 78vh;
}

.main-content {
    color: #ffffff;
    min-width: 300px;
}

.top-videos-main-container {
    display: flex;
    flex-direction: column;
}

.main-video {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main-video__image-mobile img {
    width: 100%;
    display: block;
    border-radius: 1rem;
}

.main-video__details {
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
}

.main-video__details__channel-image img {
    display: block;
    width: 3rem;
    border-radius: 50%;
}

.main-video__details__metadata {
    margin: 0 0.5rem;
}

.main-video__details__metadata__title {
    font-weight: 700;
    font-size: 15px;
    margin-top: 0.4rem;
}

.main-video__details__metadata__channel-name {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0.4rem;
    color: #808080;
}

@media (min-width: 40rem) {
    .main-video__image-desktop:hover,
    .secondary-content__image img:hover {
        box-shadow: rgb(216 223 215 / 99%) 0px 0px 11px 1px;
        cursor: pointer;
    }
}

@media (min-width: 60rem) {
    .main-container {
        padding: 2rem;
    }
}

@media (min-width: 80rem) {
    .main-container {
        min-height: 92vh;
    }

    .top-videos-main-container {
        display: flex;
        flex-direction: row;
    }

    .main-video {
        width: 72%;
    }

    .main-video__image-desktop {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        border-radius: 1rem;
    }

    .main-video__image-mobile {
        display: none;
    }

    .main-video__details__channel-image img {
        width: 5rem;
    }

    .main-video__details__channel-image img:hover {
        box-shadow: #d8dfd7fc 0px 0px 11px 1px;
        cursor: pointer;
    }

    .main-video__details__metadata__title {
        font-weight: 800;
        font-size: 25px;
        margin-top: 1rem;
    }

    .main-video__details__metadata__channel-name {
        font-weight: 400;
        font-size: 15px;
    }
}
