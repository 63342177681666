.video-player {
    position: relative;
}

.jwplayer {
    border-radius: 1rem;
}

.thumbnail-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
}

.thumbnail-details {
    margin: 0.7rem;
    position: absolute;
    left: 70%;
    top: 33%;
    z-index: 3;
    color: white;
    font-size: 0.8rem;
}

.thumbnail-details img {
    width: 100%;
    border-radius: 1rem;
}

@media (min-width: 30rem) {
    .thumbnail-details {
        top: 40%;
    }
}

@media (min-width: 40rem) {
    .thumbnail-details {
        font-size: 1rem;
        top: 50%;
    }
}

@media (min-width: 55rem) {
    .thumbnail-details {
        top: 54%;
    }
}

@media (min-width: 120rem) {
    .thumbnail-details {
        font-size: 1.3rem;
        top: 57%;
    }
}
