.channels-content {
    display: flex;
    justify-content: space-around;
}

.channels-container {
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #212121;
    border-radius: 1rem;
    color: rgba(216, 223, 215, 0.625);
    width: 90%;
}

.recommended__channel:hover {
    color: white;

    img {
        box-shadow: rgb(216 223 215 / 99%) 0px 0px 11px 1px;
    }
}

.recommended__channel__name {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 0.3rem;
}

.recommended__channel img {
    border-radius: 50%;
    width: 3rem;
    display: block;
    margin: 0.3rem auto;
}

.channels-control-button {
    display: flex;
    width: 4%;
    cursor: pointer;
    color: #d8dfd79f;
    justify-content: center;
    align-items: center;
}

.channels-control-button.inactive {
    visibility: hidden;
}

.channels-control-button svg {
    width: 50%;
    fill: #d8dfd79f;
    transform: scale(2.3);
}

.channels-control-button svg:hover {
    fill: white;
}

@media (min-width: 40rem) {
    .recommended__channel img:hover {
        cursor: pointer;
    }

    .recommended__channel img {
        width: 5rem;
    }
}

@media (min-width: 80rem) {
    .recommended__channel img {
        width: 7rem;
    }

    .recommended__channel__name {
        font-size: 1.2rem;
    }
}