@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

body {
  margin: 0;
  font-family: Rubik, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.jw-timesegment-bar jw-progress{
  background-color: #fd2121;
}
