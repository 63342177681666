.top-videos-secondary-container {
    display: none;
}

@media (min-width: 80rem) {
    .top-videos-secondary-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 2rem;
        width: 30%;
    }
    
    .secondary-content__image img {
        display: block;
        width: 100%;
        border-radius: 1rem;
    }

    .secondary-content__details {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0.5rem 0;
    }

    .secondary-content__details__channel-image img {
        display: block;
        width: 3rem;
        border-radius: 50%;
    }

    .secondary-content__details__channel-image img:hover {
        box-shadow: #d8dfd7fc 0px 0px 11px 1px;
        cursor: pointer;
    }

    .secondary-content__details__metadata {
        margin-left: 0.5rem;

        div {
            font-size: 1rem;
        }

        .secondary-content__details__metadata__title {
            font-weight: 800;
        }

        .secondary-content__details__metadata__channel-name {
            font-weight: 500;
            color: #808080;
        }
    }

    .secondary-content__details__metadata:hover {
        cursor: pointer;
    }
}