.cookies-content {
    color: white;
    position: relative;
    top: 3rem;
    padding: 0.8rem;
}

.publication-date {
    font-weight: bold;
    font-style: italic;
}

.copyright {
    font-weight: bold;
    font-style: italic;
}

@media (min-width: 60rem) {
    .cookies-content {
        padding: 2rem;
    }
}
