.header-container {
    width: 100%;
    min-width: 377px; 
    position: fixed;
    top: 0.3rem;
    z-index: 60;
    padding: 0 0.8rem;
}

.header-content {
    background-color: #201e1e;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
    border-radius: 1rem;
    box-shadow: #000000b3 0px 5px 10px;
    height: 3rem;
}

.header-content__logo img {
    height: 2rem;
}

.header-container__social-media {
    svg {
        fill: #ffffff;
        width: 2rem;
        height: 2rem;
    }

    svg:hover {
        fill: red;
    }
}

@media (min-width: 60rem) {
    .header-container {
        padding: 0 2rem;
    }
}

@media (min-width: 130rem) {
    .header-content {
        height: 4rem;
    }

    .header-content__logo img {
        height: 3rem;
    }

    .header-container__social-media svg {
        fill: #ffffff;
        width: 3rem;
        height: 3rem;
    }
}
