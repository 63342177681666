.informacion-coorporativa-content {
    color: white;
    position: relative;
    top: 3rem;
    left: -15rem;
    padding: 0.8rem;
    min-height: 85vh;
}

@media (min-width: 60rem) {
    .informacion-coorporativa-content {
        padding: 2rem;
    }
}
