.privacity-content {
    color: white;
    position: relative;
    top: 3rem;
    padding: 0.8rem;
}

@media (min-width: 60rem) {
    .privacity-content {
        padding: 2rem;
    }
}
