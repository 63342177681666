.channel-page {
    width: 100%;
    position: relative;
    top: 3rem;
    padding: 0.8rem;
    min-height: 78vh;
    color: #ffffff;
}

.channel-page__header {
    display: flex;
    flex-direction: column;
    border-radius: 1rem 1rem 0 0;
    background-color: rgb(33, 33, 33);
} 

.channel-page__header__banner {
    width: 100%;
    height: 3rem;
    background-position: top;
    background-size: cover;
    border-radius: 1rem 1rem 0 0;
    background-position-y: 5%;
}

.channel-page__header__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem;
}

.channel-page__header__info__channel-image img {
    border-radius: 50%;
    width: 4rem;
}

.channel-page__header__info__channel-details {
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
}

.channel-name {
    font-weight: 700;
    font-size: 1.6rem;
    margin-bottom: 0.2rem;
}

.channel-id,
.channel-videos-number {
    font-weight: 400;
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
    color: #afa7a7;
}

.channel-page__action-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #212121;
    border-radius: 0 0 1rem 1rem;
    margin-top: 0.3rem;
    padding: 1rem;
}

.channel-page__action-menu button {
    font-weight: 550;
    font-size: 1.1rem;
    border: none;
    cursor: pointer;
    background-color: #212121;
    color: #b3a7a7;
}

.channel-page__action-menu button:hover,
.channel-page__action-menu button:active,
.channel-page__action-menu button.active {
    text-decoration-line: underline;
    text-decoration-thickness: 0.2rem;
    color: #ffffff;
}

.video-details__image img:hover {
    box-shadow: none;
    cursor: auto;
}

@media (min-width: 35rem) {
    .channel-page__header__banner {
        height: 4rem;
    }
}

@media (min-width: 45rem) {
    .channel-page__header__banner {
        height: 5rem;
    }
}

@media (min-width: 60rem) {
    .channel-page {
        padding: 2rem;
    }
}

@media (min-width: 65rem) {
    .channel-page__header__banner {
        height: 6rem;
    }

    .channel-page__action-menu {
        justify-content: start;
    }

    .channel-page__action-menu button {
        margin-right: 2.5rem;
        font-size: 1.3rem;
    }
}

@media (min-width: 80rem) {
    .channel-page__header__banner {
        height: 8rem;
    }

    .channel-page__header__info__channel-image img {
        width: 6rem;
    }

    .channel-name {
        font-size: 25px;
        margin-bottom: 0.2rem;
    }
    
    .channel-id,
    .channel-videos-number {
        margin-bottom: 0.2rem;
    }
}

@media (min-width: 100rem) {
    .channel-page__header__banner {
        height: 10rem;
    }
}

@media (min-width: 160rem) {
    .channel-page__header__banner {
        height: 13rem;
        background-position-y: 6%;
    }
}
