.videos-container {
    display: grid;
    grid-template-columns: auto;
}

.videos-container__video {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
}

.loader {
    opacity: 0.2;
}

@media (min-width: 40rem) {
    .videos-container {
        grid-template-columns: repeat(2, 50%);
    }

    .videos-container__video {
        display: flex;
        flex-direction: column;
        margin: 1rem;
    }
}

@media (min-width: 80rem) {
    .videos-container {
        display: grid;
        grid-template-columns: repeat(3, 33%);
    }
}

@media (min-width: 90rem) {
    .videos-container {
        grid-template-columns: repeat(4, 25%);
    }
}

@media (min-width: 120rem) {
    .videos-container {
        grid-template-columns: repeat(5, 20%);
    }
}
